const initState = () => ({
  items: [],
  cartItemCount: 0,
})

export const state = initState

export const getters = {
}

export const mutations = {
  removeItem(state, {item}){
    let index = state.items.indexOf(item)
    if (index > -1){
      state.items.splice(index,1)
    }
  },
  resetItems(state){
    state.items = []
    state.cartItemCount = 0
  },
  addQuantity(state, {item}) {
    let index = state.items.indexOf(item)
    state.items[index].quantity++
  },
  subtractQuantity(state, {item}){
    let index = state.items.indexOf(item)
    state.items[index].quantity--
  },
  setAttributes(state, {attributes}) {
    state.attributes = attributes
  },
  updateCartItemCount(state){
    state.cartItemCount = 0
    state.items.forEach(x => state.cartItemCount += x.quantity)
  },
  loadShoppingCart(state,{shoppingCart}){
    shoppingCart.items.forEach(x => state.items.push(x))
  },
  updateItemDiscount(state,{item, discount, markupType}){
    let index = state.items.indexOf(item)
    state.items[index].itemTypeData.discount = discount
    state.items[index].itemTypeData.markupType = markupType
  },
  updateItemAttribute(state,{item, attribute}){
    let updatedItem = state.items.find(i => i.id === item.id)
    if(updatedItem){
      updatedItem.itemTypeData.attribute = attribute
    }
  },
  updateItemSelectedQuantity(state, {item, selectedQuantity}){
    let updatedItem = state.items.find(i => i.id === item.id)
    if(updatedItem){
      updatedItem.selectedQuantity = selectedQuantity
    }
  },
  updateCartItemQuantities(state, {items}){
    items.forEach(item => {
      let updatedItem = state.items.find(i => i.id === item.id)
      if(updatedItem){
        updatedItem.quantity = item.selectedQuantity
      }
    })
  },
}

export const actions = {
  async fetchItemByCode({commit,dispatch, state}, {filters}){
    let response
    try{
      let item = (await this.$axios.post(`webpos/products/getbysku`, filters)).data;
      if(item.id){
        item.actualItemId = item.id
        item.id = item.itemTypeID
        item.productImageURLs = item.imageURLs
        item.notes = item.itemNote

        let index = state.items.findIndex( x => x.itemTypeData.actualItemID === item.actualItemId);
        if (index !== -1 && state.items[index].quantity + 1 > item.totalInventoryQuantity){
          return 0;
        }

        item.attributes = await this.$axios.$get(
          `webpos/products/${item.itemTypeID}/${item.actualItemId}/customattributevalues`
        )

        dispatch('addToCart', {item})
        response = item
      }
      else{
        let result = await this.$axios.$get(`webpos/itemtypes/get-itemtype-by-SKU?${new URLSearchParams(filters).toString()}`, filters)
        let item = result.value
        if(item && item.id){
          dispatch('addToCart', {item})
          response = item
        } else {
          response = null
        }
      }
      if (response === 0){
        this.$toast.error('There is insufficient quantity on inventory')
      }
      else if (!response) {
        this.$toast.error('Item with this code does not exist')
      }
      else {
        this.$toast.success('Item added successfully')
      }
    }
    catch(e){
      this.$toast.error('Error while getting the item by the scanned code')
    }
  },
  async FetchItemAttributes({commit}, {item}){
    return await this.$axios.$get(`/itemtypes/${item.ID}/customattributes`).then(res =>{
    })
  },

  async addMultipleItemsToCart({commit, dispatch, state}, {shoppingCartItems}) {
    let success = true
    try{

      await this.$axios.$post('/webpos/shopping-cart/add-multiple-items-to-cart', shoppingCartItems)
      dispatch('getBySessionId')
      this.$toast.success('Item added successfully')
    }
    catch{
      this.$toast.error('Error while adding item to the cart')
      success = false
    }

    return success
  },
  async addToCart({commit, dispatch, state}, {item}) {
    if (item.actualItemId > 0){
      let invItem = await this.$axios.$get(`/webpos/products/${item.actualItemId}`);

      let index = state.items.findIndex( x => x.itemTypeData.actualItemID === item.actualItemId);

      if (index !== -1 && state.items[index].quantity + 1 > invItem.value.quantity){
        this.$toast.error('There is insufficient quantity on inventory');
        return;
      }
    }

    const shoppingCartItem = {
      ItemTypeID: item.id,
      Quantity: item.quantity ? item.quantity : 1 ,
      Notes: item.notes ? item.notes : '',
      Code: item.code,
    }

    shoppingCartItem.ItemTypeData = {
      ItemTypeID: item.id,
      Quantity: item.quantity ? item.quantity : 1,
      Price: item.price ? item.price : 0,
      RetailPrice: item.retailPrice || 0,
      BaseCost: item.cost ? item.cost : 0,
      ImageUrl:
        item.productImageURLs.length > 0
          ? item.productImageURLs[0]
          : null,
      Code: item.code,
      Name: item.name,
      PriceType: item.priceType,
      groupID: item.groupID,
      Notes: item.notes ? item.notes : '',
      attributes: item.attributes || [],
      inventoryItemId: item.inventoryItemId ? item.inventoryItemId : item.inventoryItemID ? item.inventoryItemID : null ,
      usePriceFromCartItem: !!item.actualItemId,
      itemSKU: item.itemSKU,
      actualItemId: item.actualItemId ? item.actualItemId : null,
      locationID: item.locationID || null,
      selectedActualItemID: item.selectedActualItemID || null,
      measureUnitSymbol: item.measureUnitSymbol || '',
    }

    const addToCartResult = await this.$axios.$post(
      '/webpos/shopping-cart/add-to-cart',
      shoppingCartItem
    )
    if (addToCartResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator'
      )
    }
    else{
      dispatch('getBySessionId')
    }

    return addToCartResult
  },




  async getBySessionId({commit}) {
    const shoppingCart = await this.$axios.$get(
      '/webpos/shopping-cart/get-by-user-id'

    )

    commit('resetItems')
    commit('loadShoppingCart',{shoppingCart})
    commit("updateCartItemCount")
  },

  async updateAddQuantity({commit,state},{item}){
    commit('addQuantity', {item})
    let index = state.items.indexOf(item)
    let quantity = state.items[index].quantity

    if (item.itemTypeData.actualItemID > 0){
      let invItem = await this.$axios.$get(`/webpos/products/${item.itemTypeData.actualItemID}`);
      if (invItem.value.quantity < quantity){
        commit('subtractQuantity', {item})
        this.$toast.error(
          'There is insufficient quantity on inventory'
        );
        return;
      }
    }

    const updateResult = await this.$axios.$post(
      'webpos/shopping-cart/update-quantity',
      {id: item.id, quantity: quantity}
    )
    if (updateResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator'
      )
    }

    commit("updateCartItemCount")
  },
  async updateSubtractQuantity({commit,state,dispatch},{item}) {
    commit('subtractQuantity', {item})
    let index = state.items.indexOf(item)

    let quantity = state.items[index].quantity
    const updateResult = await this.$axios.$post(
      'webpos/shopping-cart/update-quantity',
      {id: item.id, quantity: quantity}
    )
    if (updateResult.hasErrors) {
      this.$toast.error(
        'Something went wrong please contact the site administrator'
      )
    }

    if (quantity <= 0){
      dispatch('deleteCartItem',{item})
    }

    commit("updateCartItemCount")
  },
  async updateMultipleQuantity({commit,state,dispatch}, {shoppingCartItems} ){
    try{
      await this.$axios.$post(
        'webpos/shopping-cart/update-multiple-quantities',
        shoppingCartItems.map(x => { return {id: x.id, quantity: x.selectedQuantity} }))

      commit("updateCartItemQuantities", {items: shoppingCartItems})
      commit("updateCartItemCount")
    }
    catch(e){
      this.$toast.error('Error while updating item quantity')
    }
  },

  async deleteCartItem({commit},{item}){
    const updateResult = await this.$axios.$delete(
      `/webpos/shopping-cart/delete-cart-item/${item.id}`
    )
    commit('removeItem',{item})
    commit("updateCartItemCount")
  },

  async deleteCartItems({commit, state, dispatch}, {itemsForDelete}){

    try{
      await this.$axios.post('webpos/shopping-cart/items', itemsForDelete.map(x => x.id))

      itemsForDelete.forEach(item => { commit('removeItem', {item} ) })
      commit("updateCartItemCount")

    }
    catch(e){
      this.$toast.error('Error while delete item from shopping cart')
    }
  },

  async clearCart({commit, state}){
    if(state.items && state.items.length > 0){
      const clearResult = await this.$axios.$post(
        `/webpos/shopping-cart/clear-cart`
      )
      commit('resetItems')
      commit("updateCartItemCount")

    }
  },

  async updateCartItem({commit,state,dispatch},{item, discount, markupType, attribute}){
    commit('updateItemDiscount', {item: item, discount: discount, markupType: markupType})
    commit('updateItemAttribute', {item: item, attribute: attribute})
    try {
      let index = state.items.indexOf(item)
      let shoppingCartItem = state.items[index]
      const updateResult = await this.$axios.$post(
        'webpos/shopping-cart/update-cart-item',
        shoppingCartItem
      )
      if (updateResult.hasErrors) {
        this.$toast.error(
          'Something went wrong please contact the site administrator'
        )
      }
      this.$toast.success('Cart item saved successfully')
    }
    catch(e) {
      this.$toast.error('Error while saving the cart item')
    }
  },

  async updateDiscounts({commit, state, dispatch}, {itemsForUpdate, discount, markupType}){

    try{
      await this.$axios.put('webpos/shopping-cart/items', itemsForUpdate)

      itemsForUpdate.forEach(item => {
        commit('updateItemDiscount', {item: item, discount: discount, markupType: markupType})
      })

    }
    catch(e){
      this.$toast.error('Error while updating item discount')
    }
  },


}
