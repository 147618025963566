const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {
  async getLocationsForFilter({commit}, filter) {
    let locations = []
    try {
      const result = await this.$axios.$get(`/webpos/location/list-items?${new URLSearchParams(filter).toString()}`)

      if (result && !result.hasErrors) {
        locations = result.value
      }
      else {
        this.$toast.error('Error while getting locations')
      }
    }
    catch {
      this.$toast.error('Error while getting locations')
    }

    return locations
  },
}
